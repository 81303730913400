<template>
  <div id="app" ref="app_ref" style="min-width: 320px">
    <router-view :key="$route.fullPath" />
  </div>
</template>

<script>
import Network from "./js/network.js";
import { Plugin } from "vue-fragment";
Vue.use(Plugin);

export default {
  name: "App",
  mounted() {
    this.$root.$Network = Network;
  },
};
</script>

<style lang="scss">
@import "./scss/index.scss";

/* no data img */
.no-data {
  margin: 2rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  scale: 0;
  opacity: 0;
  animation: fadeIn 0.5s forwards;
  animation-delay: 0.2s;

  img {
    width: 600px;
  }

  @media screen and (max-width: 950px) {
    padding: 8px 30px;

    img {
      width: 100%;
    }
  }

  @media screen and (max-width: 480px) {
    img {
      width: 300px;
    }
  }

  h2 {
    margin: 1rem 0;
    font-family: Helvetica;
    font-weight: bold;
    font-size: 22px;
    line-height: 24px;
    letter-spacing: 0;
    color: $small-font-color;
  }
}

@keyframes fadeIn {
  to {
    scale: 1;
    opacity: 1;
  }
}

// translator
.language-item:hover {
  .language__text {
    color: var(--iconHoverColor) !important;
  }
}

#goog-gt-tt {
  display: none !important;
}

.skiptranslate {
  display: none;
}

.language-item {
  padding: 12px 16px;
}

.language-item:hover {
  text-decoration: none !important;
  background: #F2F2F2;
}

.language-item img {
  display: none;
}

.language__text {
  color: #000;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0 !important;
  margin: 0 !important;
}
</style>

<style>
#app {
  font-family: Helvetica, Arial, sans-serif;
  /*font-family: Avenir, Helvetica, Arial, sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
  width: 100%;
}

*,
::after,
::before {
  box-sizing: content-box;
}

body {
  /* why position will be fixed ? */
  position: unset !important;
  margin: 0 !important;
  height: 100%;
  width: 100%;
  min-width: 320px;
}

html {
  height: 100%;
  width: 100%;
}

ul,
ol {
  list-style: none;
  padding: 0;
  margin: 0;
}

.page {
  height: 100%;
  width: 100%;
}

/* backdrop */
.backdrop {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
}

.no-scroll-bar {
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
  overflow-y: scroll;
}

.no-scroll-bar::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

/* fade */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave

/* .fade-leave-active below version 2.1.8 */
  {
  opacity: 0;
}

/* fade-in */
.fade-in-enter-active {
  transition: opacity 0.5s;
}

.fade-in-leave-active {
  transition: opacity 0s;
}

.fade-in-enter,
.fade-in-leave-to

/* .fade-leave-active below version 2.1.8 */
  {
  opacity: 0;
}

/* slide-fade */
.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.slide-fade-leave-active {
  transition: all 0.3s ease-out;
}

.slide-fade-enter,
.slide-fade-leave-to

/* .slide-fade-leave-active below version 2.1.8 */
  {
  transform: translateY(-10px);
  opacity: 0;
}

/* slide-left-fade */
.slide-left-fade-enter-active {
  transition: all 0.1s ease;
}

.slide-left-fade-leave-active {
  transition: all 0.1s ease-out;
}

.slide-left-fade-enter,
.slide-left-fade-leave-to

/* .slide-left-fade-leave-active below version 2.1.8 */
  {
  transform: translateX(-10px);
  opacity: 0;
}

/* slide-bottom-to-up */
.slide-bottom-to-up-enter-active {
  transition: all 0.2s ease-out;
}

.slide-bottom-to-up-leave-active {
  transition: all 0.2s ease-in;
}

.slide-bottom-to-up-enter,
.slide-bottom-to-up-leave-to

/* .slide-bottom-to-up-leave-active below version 2.1.8 */
  {
  transform: translateY(10px);
  opacity: 0;
}
</style>
